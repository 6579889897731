<template>
  <!-- eslint-disable max-len -->
  <div :id="`btnFlex-${title}`">
    <button
      @click="handlerClick()"
      @mouseover="btnHover(true)"
      @mouseleave="btnHover(false)"
      :class="[classBtn]"
      :disabled="BtnFlex.loading || BtnFlex.error || BtnFlex.success || disabled"
      ref="refBtnFlex"
      :type="type"
    >
      <template v-if="BtnFlex.loading">
          <svg class="animate-spin w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
      </template>
      <template v-else-if="BtnFlex.success">
        <font-awesome-icon class="text-white-400" icon="check-circle" />
      </template>
      <template v-else-if="BtnFlex.error || file.error">
        <template v-if="BtnFlex.textError != ''">
          {{BtnFlex.textError}}
        </template>
        <template v-else-if="file.textError != ''">
          {{file.textError}}
        </template>
        <template v-else>
          <font-awesome-icon class="text-white-400" icon="exclamation-circle" />
        </template>
      </template>
      <template v-else> {{BtnFlex.text}}
      </template>
    </button>
    <template v-if="type === 'file' || type === 'image'">
      <input  v-show="false" @click="resetEvent" @change="uploadFile" type="file" ref="refFile">
    </template>
  </div>

</template>

<script>
// @group Button
export default {
  emits: ['btnSubmit', 'btnActive', 'setImage', 'fileState'],
  props: {
    // Contiene clases (base , hover, successClass, errorClass)
    // ,text (placeholder) y loading (true o false), success (true o false), textError, value, src, rendering (estado del renderizado de imagen)
    BtnFlex: {
      type: Object,
    },
    // Nombre personalizado de la clase
    title: {
      type: String,
      required: true,
    },
    // file,submit
    type: {
      type: String,
    },
    // true para archivo imagen y agregar atributos
    img: {
      type: Boolean,
      default() {
        return false;
      },
    },
    // Aun no definido
    excel: {
      type: Boolean,
      default() {
        return false;
      },
    },
    // Desactiva el componente
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  computed: {
    classBtn() {
      let classBtn;
      if (this.disabled) {
        classBtn = `${this.BtnFlex.base} btnFlex disabled`;
      } else if (this.BtnFlex.loading) {
        classBtn = `${this.BtnFlex.base} btnFlex`;
      } else if (this.BtnFlex.success) {
        classBtn = `${this.BtnFlex.successClass} btnFlex`;
      } else if (this.BtnFlex.error || this.file.error) {
        classBtn = `${this.BtnFlex.errorClass} btnFlex`;
      } else {
        classBtn = `${this.BtnFlex.base} btnFlex`;
      }
      return classBtn;
    },
  },
  data() {
    return {
      file: {
        error: false,
        textError: '',
      },
    };
  },
  methods: {
    handlerClick() {
      switch (this.type) {
        case 'submit':
          this.btnSubmit();
          break;
        case 'file':
          this.$refs.refFile.click();
          break;
        case 'image':
          this.$refs.refFile.click();
          break;
        default:
          break;
      }
    },
    btnSubmit() {
      this.$emit('btnSubmit');
    },
    btnHover(flag) {
      return flag && !this.BtnFlex.loading && !this.BtnFlex.error
        ? this.$refs.refBtnFlex?.classList.add(this.BtnFlex.hover)
        : this.$refs.refBtnFlex?.classList.remove(this.BtnFlex.hover);
    },
    async uploadFile(event) {
      if (this.type === 'image') {
        if (event.target.files[0] !== undefined && event.target.files[0] !== '') {
          const validate = await this.validateImage(event.target.files[0]);
          if (validate) {
            const reader = new FileReader();
            this.$emit('fileState', reader.readyState);
            reader.onload = (e) => {
              this.$emit('fileState', reader.readyState);

              const image = {
                render: e.target.result,
                file: event.target.files[0],
              };
              this.$emit('setImage', image);
            };

            this.$emit('fileState', reader.readyState);
            reader.readAsDataURL(event.target.files[0]);
          } else {
            this.$emit('setImage', false);
          }
        }
      }
    },
    validateImage(file) {
      return new Promise((resolve) => {
        this.file.error = false;
        this.file.textError = '';
        const allowed = ['image/png', 'image/jpeg', 'image/jpg'];
        if (!allowed.includes(file.type)) {
          this.file.error = true;
          this.file.textError = 'La imagen debe ser png, jpg o jpeg';
          setTimeout(() => {
            this.file.error = false;
            this.file.textError = '';
          }, 9000);
          resolve(false);
          // reject(new Error('La imagen debe ser png, jpg o jpeg'));
        }
        resolve(true);
      });
    },
    resetEvent() {
      this.$refs.refFile.value = '';
    },
    addAtributte() {
      if (this.type === 'file') {
        if (this.img) {
          this.$refs.refFile.setAttribute('accept', 'image/png, image/jpeg, image/jpg');
        }
      }
    },
  },
  mounted() {
    this.addAtributte();
  },
};
</script>

<style scoped>
.btnFlex {
  border-radius: 6px;
  width: 100%;
  height: 3.5rem;
  font-weight: 600;
  letter-spacing: 0.8px;
  transition: all 0.3s;
  display:flex;
  justify-content: center;
  align-items: center;
}
.disabled {
  background-color:#b1bbb778 !important;
}

.disabled:hover {
  background-color:#b1bbb778 !important;
}
</style>
