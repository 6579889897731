<template>
  <div>
    <div
      class="flex justify-start ml-8 mt-4 text-lg cursor-pointer"
      @click="redirectBack"
    >
      <font-awesome-icon class="text-blue-400" icon="arrow-left" />
    </div>
    <main class="container mx-auto container-center-section">
      <div class="box-section">
        <div class="box-section__logo mt-8">
          <img :src="BaseUrl + '/images/logo/devuelvoya.png'" />
        </div>
        <div class="box-section__title my-6">
          <h2>Restablecer contraseña</h2>
          <span>Por favor, introduce tu correo electronico</span>
        </div>
        <div class="box-section__form">
          <form @submit.prevent="tryResetPassword()" id="formRegister">
            <div class="my-2">
              <BaseInput
                type='text'
                :placeholder="$t('Email')"
                outline="blue"
                v-model:modelValue="restorePassword.email.value"
                :error="restorePassword.email.error"
                @error-handle="restorePassword.email.error = $event"
              />
            </div>
            <div class=" my-3">
              <BtnFlex
              title='btn-restorePassword'
              type='send'
              :BtnFlex="restorePassword.button" />
            </div>
            <div
              v-if="restorePassword.error"
              class="
                mt-4
                rounded
                border-2 border-red-400
                text-red-500 text-center
                p-3
              "
            >
              {{ restorePassword.error }}
            </div>
          </form>
        </div>
      </div>
    </main>
  </div>

</template>

<script>
import { mapActions } from 'vuex';
import baseUrl from '@/services/Api/BaseUrl';
import AuthenticationRepository from '@/repositories/Authentication/AuthenticationRepository';

import BtnFlex from '@/components/Form/BtnFlex.vue';
//

export default {
  components: { BtnFlex },
  data() {
    return {
      BaseUrl: baseUrl.apiDevuelvoya,
      restorePassword: {
        email: {
          value: '',
          error: '',
        },
        button: {
          base: 'c-text-white c-bg-blue',
          hover: 'c-bg-blue-light',
          successClass: 'c-text-white c-bg-green',
          errorClass: 'c-text-white c-bg-red',
          text: 'Continuar',
          loading: false,
          error: false,
          success: false,
          textError: '',
        },
        error: '',
      },
    };
  },
  methods: {
    ...mapActions('auth', ['setToken']),
    async tryResetPassword() {
      this.restorePassword.button.loading = true;
      const restorePassword = {
        email: this.restorePassword.email.value,
      };
      await AuthenticationRepository.restorePassword(restorePassword)
        .then(() => {
          this.restorePassword.button.success = true;
          this.errorHandle(null, false);
          this.$router.push({ name: 'EmailSent' });
        })
        .catch((err) => {
          this.errorHandle(err, true);
        })
        .finally(() => {
          this.restorePassword.button.loading = false;
        });
    },
    errorHandle(err, error) {
      this.cleanErrors();
      if (error) {
        const { status, data: { errors } } = err.response;
        const { email, message } = errors;

        this.restorePassword.button.error = true;
        setTimeout(() => {
          this.restorePassword.button.error = false;
        }, 3000);

        if (status === 422) {
          if (email) {
            const [errorEmail] = email;
            this.restorePassword.email.error = errorEmail;
          }
        } else if (message) {
          const [messageResponse] = message;
          this.restorePassword.error = messageResponse;
        }
      }
    },
    cleanErrors() {
      if (this.restorePassword.button.error) {
        this.restorePassword.button.error = false;
      }
      if (this.restorePassword.email.error !== '') {
        this.restorePassword.email.error = '';
      }
      if (this.restorePassword.error !== '') {
        this.restorePassword.error = '';
      }
    },
    redirectBack() {
      this.$router.push({ name: 'Login' });
    },
  },
};
</script>

<style lang="css">
@import '../../../assets/css/utils/formCenter.css';
</style>
