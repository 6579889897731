<template>
  <div>
    <div
      class="flex justify-start ml-8 mt-4 text-lg cursor-pointer"
      @click="redirectBack"
    >
      <font-awesome-icon class="text-blue-400" icon="arrow-left" />
    </div>
    <main class="container mx-auto container-center-section">
      <div class="box-section">
        <div class="box-section__logo mt-8">
          <img :src="BaseUrl + '/images/logo/devuelvoya.png'" />
        </div>
        <div class="box-section__title my-6">
          <h2>Nueva contraseña</h2>
          <span v-if="changePassword"> Por favor, introduce tu contraseña actual</span>
          <span v-else>Por favor, introduce tu nueva contraseña</span>
        </div>
        <div class="box-section__form">
          <form @submit.prevent="tryNewPassword()" id="formRegister">
            <div class="my-2">
              <BaseInput
                type='password'
                :placeholder="changePassword ? 'Contraseña actual' : 'Contraseña'"
                outline="blue"
                v-model:modelValue="newPassword.password.value"
                :error="newPassword.password.value.error"
                @error-handle="newPassword.password.error = $event"
              />
            </div>
            <template v-if="changePassword">
              <div class="my-2">
                <BaseInput
                  type='password'
                  placeholder="Nueva contraseña"
                  outline="blue"
                  v-model:modelValue="newPassword.newPassword.value"
                  :error="newPassword.newPassword.error"
                  @error-handle="newPassword.newPassword.error = $event"
                />
              </div>
              <div class="my-2">
                <BaseInput
                  type='password'
                  placeholder="Confirma contraseña"
                  outline="blue"
                  v-model:modelValue="newPassword.confirmPassword.value"
                  :error="newPassword.confirmPassword.error"
                  @error-handle="newPassword.confirmPassword.error = $event"
                />
                </div>
            </template>

            <div class=" my-3">
              <BtnFlex
              title='btn-newPassword'
              type='send'
              :BtnFlex="newPassword.button"
              :disabled="newPassword.button.loading"
               />
            </div>
            <div
              v-if="newPassword.error"
              class="mt-4 rounded border-2 border-red-400 text-red-500 text-center p-3"
            >
              {{ newPassword.error }}
            </div>
          </form>
        </div>
      </div>
    </main>
  </div>

</template>

<script>
import { mapActions } from 'vuex';
import baseUrl from '@/services/Api/BaseUrl';
import AuthenticationRepository from '@/repositories/Authentication/AuthenticationRepository';
import BtnFlex from '@/components/Form/BtnFlex.vue';

export default {
  components: { BtnFlex },
  computed: {
    changePassword() {
      const { name } = this.$route;
      if (name === 'changePassword') {
        return true;
      }
      return false;
    },
  },
  async created() {
    if (this.$route.name === 'NewPassword') {
      localStorage.setItem('token', this.$route.params.token);
      this.setPropAuth({ key: 'loadingAuth', value: true });
      try {
        const response = await AuthenticationRepository.checkTokenRestore();
        const { token, abilities } = response.data;
        if (token) {
          localStorage.setItem('token', token);
          this.setPropAuth({ key: 'token', value: token });
        }
        if (abilities) {
          this.setPropAuth({ key: 'abilities', value: abilities });
        }
        this.setPropAuth({ key: 'loadingAuth', value: false });
      } catch (error) {
        this.setPropAuth({ key: 'loadingAuth', value: false });
        // this.$router.push({ name: 'Login' });
      }
    }
  },
  data() {
    return {
      BaseUrl: baseUrl.apiDevuelvoya,
      newPassword: {
        password: {
          value: '',
          error: '',
        },
        newPassword: {
          value: '',
          error: '',
        },
        confirmPassword: {
          value: '',
          error: '',
        },
        button: {
          base: 'c-text-white c-bg-blue',
          hover: 'c-bg-blue-light',
          successClass: 'c-text-white c-bg-green',
          errorClass: 'c-text-white c-bg-red',
          text: 'Continuar',
          loading: false,
          error: false,
          success: false,
          textError: '',
        },
        error: '',
      },
    };
  },
  methods: {
    ...mapActions('auth', ['setToken', 'setUser', 'removeToken', 'setPropAuth']),
    async tryNewPassword() {
      this.newPassword.button.loading = true;
      const newPassword = {
        password: this.newPassword.password.value,
      };

      if (this.changePassword) {
        newPassword.actionType = 'update';
        newPassword.newPassword = this.newPassword.newPassword.value;
        newPassword.confirmPassword = this.newPassword.confirmPassword.value;
      }

      await AuthenticationRepository.newPassword(newPassword)
        .then(() => {
          this.newPassword.button.success = true;
          this.errorHandle(null, false);
          if (this.changePassword) {
            setTimeout(() => {
              this.$router.push({ name: 'User' });
            }, 1500);
          } else {
            this.setUser({});
            this.removeToken();
            setTimeout(() => {
              this.$router.push({ name: 'Login' });
            }, 1500);
          }
        })
        .catch((err) => {
          this.errorHandle(err, true);
        })
        .finally(() => {
          this.newPassword.button.loading = false;
        });
    },
    errorHandle(err, error) {
      this.cleanErrors();
      if (error) {
        const { data: { errors } } = err.response;
        const {
          password, newPassword, confirmPassword, message,
        } = errors;

        this.newPassword.button.error = true;
        setTimeout(() => {
          this.newPassword.button.error = false;
        }, 3000);

        if (password) {
          const [errorPassword] = password;
          this.newPassword.password.error = errorPassword;
        }
        if (newPassword) {
          const [errorPassword] = newPassword;
          this.newPassword.newPassword.error = errorPassword;
        }
        if (confirmPassword) {
          const [errorPassword] = confirmPassword;
          this.newPassword.confirmPassword.error = errorPassword;
        }
        if (message) {
          const [messageResponse] = message;
          this.newPassword.error = messageResponse;
        }
      }
    },
    cleanErrors() {
      if (this.newPassword.button.error) {
        this.newPassword.button.error = false;
      }
      if (this.newPassword.password.error !== '') {
        this.newPassword.password.error = '';
      }
      if (this.newPassword.newPassword.error !== '') {
        this.newPassword.newPassword.error = '';
      }
      if (this.newPassword.confirmPassword.error !== '') {
        this.newPassword.confirmPassword.error = '';
      }
      if (this.newPassword.error !== '') {
        this.newPassword.error = '';
      }
    },
    redirectBack() {
      if (!this.changePassword) {
        this.$router.push({ name: 'RestorePassword' });
      } else {
        this.$router.push({ name: 'Settings' });
      }
    },
  },
};
</script>

<style lang="css">
@import '../../../assets/css/utils/formCenter.css';
</style>
