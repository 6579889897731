<template>
  <div>
    <div
      class="flex justify-start ml-8 mt-4 text-lg cursor-pointer"
      @click="redirectBack"
    >
      <font-awesome-icon class="text-blue-400" icon="arrow-left" />
    </div>
    <main class="container mx-auto container-center-section">
      <div class="box-section">
        <div class="box-section__logo mt-8">
          <img :src="BaseUrl + '/images/logo/devuelvoya.png'" />
        </div>
        <div class="box-section__title my-6">
          <h2>Te hemos enviado un correo electrónico</h2>
          <span>Tienes 24 hs para ingresar a tu bandeja de  <span class="text-blue-600">entrada</span> ó <span class="text-blue-600">spam</span> y seguir las intrucciones.   </span>
        </div>
        <div class="flex justify-center flex-col mx-auto my-6 sm:my-2 px-1">
            <img class="w-20 mx-auto my-2" :src="BaseUrl + '/images/work/sent.png'" />
          </div>
      </div>
    </main>
  </div>

</template>

<script>
import { mapActions } from 'vuex';
import baseUrl from '@/services/Api/BaseUrl';

export default {
  data() {
    return {
      BaseUrl: baseUrl.apiDevuelvoya,
    };
  },
  methods: {
    ...mapActions('auth', ['setToken']),
    redirectBack() {
      this.$router.push({ name: 'RestorePassword' });
    },
  },
};
</script>

<style lang="css">
@import '../../../assets/css/utils/formCenter.css';
</style>
