<template>
  <div>
     <LoaderDoom
      LoaderDoomClass="LoaderDoom"
      spinnerClass="animate-spin w-10 text-blue-400"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import AuthenticationRepository from '@/repositories/Authentication/AuthenticationRepository';
import LoaderDoom from '@/components/General/LoaderDoom.vue';

export default {
  components: { LoaderDoom },
  computed: {
    ...mapState('auth', ['token']),
    ...mapState('sidebar', ['openSidebar']),
  },
  methods: {
    ...mapActions('auth', ['removeToken', 'setUser', 'cleanStore']),
    ...mapActions('sidebar', ['hiddenSidebar']),
    async logout() {
      const tokenLocal = JSON.parse(JSON.stringify(this.token));
      const cerrarSesion = `${process.env.VUE_APP_OPERACIONES_CONTROLADOR}?token=${tokenLocal}&redirectTo=/autenticacion/logout`;
      await AuthenticationRepository.logout({ token: this.token })
        .then(() => {
          
          this.setUser({});
          this.removeToken();
          this.cleanStore();
          if (this.openSidebar) {
            this.hiddenSidebar();
          }
          
          window.location.href = cerrarSesion;
          // this.$router.push({ name: 'Login' });
        })
        .catch(() => {
          // this.$router.push({ name: 'Login' });
          window.location.href = cerrarSesion;
        });
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const object = vm;
      object.logout();
    });
  },
};
</script>
